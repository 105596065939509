footer {
     // font-family: $font-Poppins;
     position: relative;
     padding: 80px 0 0px;
     color: var(--txt-white);
     overflow: hidden;
     background-color: var(--theme-dark-section);


     .__dc-footer {
          width: 100%;

          .__txt {
               padding: 20px;
               border-radius: 20px;
               text-align: center;
               margin-bottom: 50px;

               .logo {
                    text-decoration: none;
                    width: 230px;
                    height: 80px;
                    display: inline-block;
                    margin-bottom: 20px;

                    img {
                         object-fit: contain;
                         width: 100%;
                         height: 100%;
                    }
               }

               h5 {
                    font-size: 30px;
                    margin-bottom: 0;
                    font-weight: 300;
                    margin-bottom: 20px;
               }

               p {
                    margin-left: 20px;
                    opacity: 0.6;
                    font-size: 13px;
                    font-weight: 300;
               }

               .btn{
                    background-color: transparent;
                    border: 1px solid var(--theme-dark-border) !important;
                    padding: 10px 30px;
                    border-radius: 30px;
                    margin-top: 20px;
                    font-size: 14px;
                    color: var(--txt-white);
                    transition: all 0.3s linear;

                    &:hover{
                         background-color: var(--theme-dark-border) !important;
                         border-color: var(--txt-white);
                         color: var(--txt-white);
                    }
               }
          }




          .__list {
               height: 100%;

               @media ($breakpoint_MD){
                    border-top: 1px solid var(--theme-dark-border);
                    padding: 20px 0;
               }

               ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    &:hover {
                         li{
                              filter: blur(7px);

                              @media ($breakpoint_MD){
                                   filter: blur(0);
                              }
                         }
                    }

                    li {
                         width: 60%;
                         margin: auto;
                         transition: all 0.3s linear;

                         @media ($breakpoint_MD){
                              width: 100%;
                         }

                         &:hover {
                              filter: blur(0);
                              
                              a{
                                   opacity: 1;
                              }
                         }



                         a {
                              display: flex;
                              align-items: center;
                              justify-content: flex-start;
                              text-decoration: none;
                              color: var(--txt-white);
                              padding: 10px;
                              gap: 10px;
                              opacity: 0.7;
                              text-transform: capitalize;
                              transition: all 0.3s linear;

                              &:hover{
                                   background-color: var(--theme-dark-border);
                                   border-radius: 10px;
                              }

                              p {
                                   margin-bottom: 0;
                                   font-weight: 300;
                              }

                              svg{
                                   opacity: 0.7;
                              }
                         }
                    }
               }
          }


     }

     .copy-right {
          text-align: center;
          margin-top: 100px;
          border-top: 1px solid rgb(146 146 145 / 27%);
          padding: 20px 0 10px;
          font-weight: 300;


          p {
               font-size: 14px;
          }
     }
}
.__docs{
    padding: 200px 100px 400px;
    font-family: $font-Poppins;

    .heading{
        text-align: center;
        margin-bottom: 20px;
    }

    .accordion{
        color: var(--txt-white);

        .accordion-item{
            background-color: transparent;
            border: 1px solid #dddddd62 !important;
            margin-bottom: 20px;
            border-radius: 10px !important;
            overflow: hidden;

            .accordion-button{
                background-color: #202020;
                border: 0;
                color: var(--txt-white);
                box-shadow: unset;
            }

            .accordion-body{
                color: var(--txt-white);
                opacity: 0.6;
                // color: var(--txt-black);
            }
        }
    }

    ul{
        list-style-type: decimal;

        .li_heading{
            font-size: 20px;
            display: block;
            margin-bottom: 10px;
            
        }

        li{
            p{
                opacity: 0.7;
                margin-bottom: 4px;
            }
        }
    }
}
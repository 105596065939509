.__login {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: #f3e7fa;
    color: var(--txt-black);


    @media ($breakpoint_MD) {
        display: block;
        padding-top: 60px;

    }




    .__contaer {
        max-width: 900px;
        width: 100%;
        margin: auto;
        font-family: $font-Poppins;

        .__logo {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 30px;
            text-align: center;

            @media ($breakpoint_MD) {
                align-items: flex-start;
                text-align: left;
            }

            a {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 80px;
                    height: 80px;
                    background-color: var(--primary-color);
                    border-radius: 50%;

                }
            }

            p {
                margin-bottom: 0;
                opacity: 0.6;
                font-size: 12px;
                width: 60%;

                @media ($breakpoint_MD) {
                    width: 90%;
                }
            }


        }

        .form {

            .row {
                row-gap: 20px;
            }

            .from-group {
                width: 100%;
                color: var(--txt-black);
                position: relative;
                z-index: 1;
                margin-top: 0px;


                h5 {
                    display: block;
                    width: 100%;
                    text-align: center;
                    opacity: 0.5;
                    font-size: 16px;


                    @media ($breakpoint_MD) {
                        text-align: left;
                    }

                }

                .__inputs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    gap: 10px;
                    margin-top: 20px;


                    @media ($breakpoint_MD) {
                        align-items: flex-start;
                        justify-content: flex-start;
                    }

                    input {
                        display: inline-block;
                        text-align: center;
                        width: 50px;
                        height: 55px;
                        border-radius: 10px;
                        background-color: #ffffff17;
                        border: 1.5px solid #33333378;
                        box-shadow: unset;
                        outline: 0;
                        color: var(--color-txt);
                        font-weight: 600;
                        font-size: 22px;
                        margin: 0;
                        transition: all 0.3s linear;

                        @media ($breakpoint_MD) {
                            width: 100%;
                            margin: 0;

                        }



                        &::placeholder {
                            color: var(--txt-white);
                            opacity: 0.8;
                        }

                        &:focus,
                        &:hover {
                            border-color: var(--primary-color);
                        }
                    }
                }

                .__error {
                    margin-bottom: 0;
                    color: var(--bs-danger);
                    text-align: center;
                    font-size: 13px;
                }

                &.from-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    margin-top: 20px;
                    margin-inline: auto;
                    gap: 20px;

                    @media ($breakpoint_MD) {
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-inline: inherit;
                    }

                    @media ($breakpoint_SM) {
                        width: 100%;
                    }

                    .btn-back {
                        background-color: transparent;
                        border: 1px solid var(--primary-color);
                        width: 100%;
                        border-radius: 30px;
                        transition: all 0.3s linear;
                        padding: 10px 0;

                        &:hover {
                            background-color: var(--primary-color);
                            border-color: transparent;
                        }
                    }

                    .btn-common {
                        margin-top: 0;
                        width: 100%;
                        padding: 10px 0;


                    }
                }


            }
        }
    }

}
@import '~bootstrap/scss/bootstrap'; /* including bootstrap 5  */
@import 'react-responsive-modal/styles.css';
@import 'react-toastify/dist/ReactToastify.css';


// common files
@import "./variable.scss"; 
@import "./common.scss"; 


// Layout files..
@import "./header.scss";
@import "./footer.scss";

// auth files..
@import "./login.scss";


// Pages files..
@import "./index.scss";
@import "./Homesection.scss";
@import "./aboutpage.scss";
@import "./me.scss";
@import "./license.scss";
@import "./blog.scss";
@import "./demo.scss";
@import "./codes.scss";



// Tools files
@import "./website.scss";
@import "./gradients.scss";
// @import "./GridGenerator.scss";
@import "./Svgs.scss";
@import "./colors.scss";



// body {
//     background-color: #1f1e25;
//     color: white;
//     font-family: Arial, sans-serif;
//     margin: 0;
//     padding: 20px;
// }

// #gameContainer {
//     position: relative;
//     margin-bottom: 20px;
// }

// #game {
//     width: 400px;
//     height: 350px;
//     background-color: #2c2b33;
// }

// #currentMultiplier {
//     position: absolute;
//     top: 110px;
//     left: 70px;
//     font-size: 50px;
// }

// #infoContainer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 10px;
// }

// #balance {
//     color: lime;
//     font-weight: bold;
//     margin-right: 5px;
// }

// #crashedAt {
//     margin-right: 5px;
// }

// #betContainer {
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px;
// }

// #betContainer label {
//     margin-right: 5px;
// }

// #betAmount {
//     width: 40px;
//     padding: 5px;
// }

// #buttonContainer {
//     margin-bottom: 10px;
//     width: 400px;
// }

// #submitBet,
// #takeProfits {
//     padding: 10px 20px;
//     font-size: 16px;
//     background-color: #4caf50;
//     border: none;
//     color: white;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
// }

// #submitBet:hover,
// #takeProfits:hover {
//     background-color: #45a049;
// }

// #lastCrashesContainer {
//     margin-top: 20px;
// }

// #lastCrashesContainer p {
//     font-weight: bold;
// }

// #lastCrashes {
//     margin-top: 5px;
// }


// ========================================


.__game{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: skyblue;
    position: relative;

    .__tools{
        padding: 0 30px;
        position: fixed;
        z-index: 2;
    }

    .__canvas{
        width: 100%;
        height: 100vh;

        @media ($breakpoint_MD){
            height: 40vh;
        }

        canvas{
            // height: 100% !important;
            // width: 100% !important;
            overflow: hidden !important;
        }

    }


    
}

.__appjke{
    height: 100vh;



    @media ($breakpoint_MD){
        height: 40vh;
    }
}
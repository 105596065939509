
:root {
    // color-scheme: light dark;
    //todo commmon variables
    --body: #fff;
    --txt-white: #fff;
    --txt-black: #000;
    --primary-color: #e3bc57;
    --color-txt: #2e1959;
    --base-color: #000314;

    --active-black: #000314;


    // light theme variables
    --theme-light-section: #fdfdfd;





    // dark theme variables
    --theme-dark-section: #0a0a0a;
    --theme-dark-border: #292929;
    --theme-dark-card: #141414;

    
}
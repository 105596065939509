@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap');

// font-family: "Poppins", sans-serif;
// font-family: "Bungee", serif;
// font-family: "Sofia Sans", sans-serif;

$font-Poppins: "Poppins", sans-serif;
$font-Sofia:  "Sofia Sans", sans-serif;




// todo indput arrow button remove
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
}




// todo Breakpoints....
$breakpoint_XXXL: 'max-width: 1500px';
$breakpoint_XXL: 'max-width: 1400px';
$breakpoint_XL: 'max-width: 1200px';
$breakpoint_LG: 'max-width: 992px';
$breakpoint_MD: 'max-width: 768px';
$breakpoint_SM: 'max-width: 576px';
$breakpoint_450: 'max-width: 450px';
$breakpoint_ESM: 'max-width: 385px';
$breakpoint_50: 'max-width: 350px';


body {
     // color-scheme: light;
     // background-color: light-dark(var(--light-gh), var(--dark-gh));
     background-color: #fff;
     color: var(--txt-black);
     scroll-behavior: smooth !important;
     font-family: $font-Poppins;


     &.Loader-start {
          overflow: hidden;
     }

     &::-webkit-scrollbar {
          width: 4px;
     }

     &::-webkit-scrollbar-thumb {
          background-color: var(--txt-black);
          border-radius: 20px;
     }
}

.grain {
     opacity: .02;
     background-image: url(https://cdn.prod.website-files.com/6405fcf125150a174dce6e85/6408b7e068e95e64bc1d1c58_Grain.gif);
     background-position: 50%;
     background-repeat: repeat;
     background-attachment: scroll;
     position: fixed;
     inset: 0%;
     z-index: -1;
     // display: none;
}



.btn-common {
     margin-top: 30px;
     display: inline-block;
     color: var(--txt-black);
     padding: 12px 40px;
     border: 1px solid transparent;
     background-color: var(--primary-color);
     border-radius: 30px;
     text-decoration: none;
     font-family: $font-Poppins;
     transition: all 0.3s linear;
     text-transform: capitalize;

     &:hover {
          background-color: transparent;
          border-color: currentColor;
          color: var(--primary-color);
     }
}

.__container {
     max-width: 1200px;
     width: 100%;
     margin: auto;
     padding: 0 15px;
}



.color-txt {
     background-clip: text;
     -webkit-text-fill-color: transparent;
     background-image: linear-gradient(90deg, #2e1959 0%, #c46ed382 68.2882%, #e3bc57 100%);
     font-weight: 600;
}


.__loader {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: #f3e7fa;
     color: var(--txt-black);
     z-index: 999999;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     flex-direction: column;

     .__content {

          svg,
          img {
               transform: scale(2.8);

               polyline {
                    fill: none;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
               }

               polyline#back {
                    stroke: rgba(46, 25, 89, 0.396);
               }

               polyline#front {
                    stroke: rgb(227, 188, 87);
                    stroke-dasharray: 12, 36;
                    stroke-dashoffset: 48;
                    animation: dash 1s linear infinite;
               }
          }

          .__txt {
               margin-top: 30px;
               width: 400px;

               @media ($breakpoint_MD) {
                    width: 100%;
                    padding: 0 50px;
               }

               @media ($breakpoint_SM) {
                    padding: 0 20px;
               }


               p {
                    font-size: 18px;
                    margin-bottom: 0;
                    display: inline-block;

                    @media ($breakpoint_MD) {
                         font-size: 14px;
                    }
               }

               h6 {
                    margin-top: -6px;
                    margin-bottom: 0;
               }
          }
     }




     @-moz-keyframes dash {
          62.5% {
               opacity: 0;
          }

          to {
               stroke-dashoffset: 0;
          }
     }

     @-webkit-keyframes dash {
          62.5% {
               opacity: 0;
          }

          to {
               stroke-dashoffset: 0;
          }
     }

     @-o-keyframes dash {
          62.5% {
               opacity: 0;
          }

          to {
               stroke-dashoffset: 0;
          }
     }

     @keyframes dash {
          62.5% {
               opacity: 0;
          }

          to {
               stroke-dashoffset: 0;
          }
     }
}


.react-responsive-modal-overlay {
     backdrop-filter: blur(5px);
}

.react-responsive-modal-closeButton {
     color: var(--txt-black);
     fill: var(--txt-black);
}

.code-modal {
     background-color: #fff !important;
     background: #fff !important;
     font-family: $font-Poppins;
     max-width: 900px !important;
     width: 100% !important;
     padding: 30px !important;

     .code-content {

          .heading-sx {
               margin-bottom: 20px;
          }

          p {
               opacity: 0.7;

               a {
                    color: var(--primary-color);
                    text-decoration: none !important;
                    outline: 0 !important;
                    box-shadow: 0 !important;
                    border: 0 !important;
               }
          }



          .__code {
               padding: 10px 20px 0px;
               height: 400px;
               overflow-y: scroll;
               overflow-x: auto;
               background-color: #f2f2f2;
               border-radius: 10px;


               &:hover {

                    .button-copy {
                         opacity: 1;
                    }
               }

               pre {
                    opacity: 0.8;
                    position: relative;
                    z-index: 1;

                    .button-copy {
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         position: absolute;
                         top: 5px;
                         right: 0px;
                         padding: 10px;
                         background: #191919b0;
                         color: var(--txt-white);
                         transition: all 0.3s linear;
                         opacity: 0;

                         svg {
                              transition: all 0.3s linear;
                              transform: scale(1);
                         }

                         &:hover {

                              svg {
                                   transform: scale(1.1);
                              }
                         }

                    }
               }
          }

          .__code::-webkit-scrollbar {
               width: 3px;
          }

          .__code::-webkit-scrollbar-thumb {
               background-color: var(--primary-color);
               border-radius: 30px;
          }

     }
}


.lock-modal {
     background-color: #3c3c3c !important;
     background: #3c3c3c !important;
     font-family: $font-Poppins;
     max-width: 900px !important;
     width: 100% !important;
     padding: 30px !important;
     color: var(--txt-white) !important;
}


.phone-lock {
     height: 100vh;
     width: 100%;
     background-color: var(--body);
     position: fixed;
     top: 0;
     left: 0;
     z-index: 9999;
     display: flex;
     align-items: center;
     justify-content: center;
     font-family: $font-Poppins;

     .lock-screen {
          width: 50%;
          position: relative;
          z-index: 1;

          .__logos {
               display: flex;
               align-items: center;
               justify-content: center;
               margin-bottom: 40px;

               img {
                    height: 80px;
               }
          }

     }

     form {
          position: relative;



          label {
               display: block;
               width: 100%;
               margin-bottom: 10px;
               margin-left: 20px;

          }

          input {
               width: 100%;
               padding: 15px 70px 15px 30px;
               border-radius: 30px;
               background-color: #ffffff17;
               border: 1px solid #fff;
               box-shadow: unset;
               outline: 0;
               color: var(--txt-white);


               &::placeholder {
                    color: var(--txt-white);
                    opacity: 0.8;
               }

               &:focus,
               &:hover {
                    border-color: var(--primary-color);
               }
          }

          .submit-btn {
               position: absolute;
               top: 28px;
               right: 0%;
               transform: translate(-20%, -50%);
               background-color: var(--primary-color);
               padding: 7px 20px;
               border-radius: 30px;
               border: 1px solid transparent;
               outline: 0;
               box-shadow: unset;
               transition: all 0.3s linear;

               &:hover,
               &:focus {
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                    background-color: transparent;
               }
          }
     }



     .btn-common {
          display: block;
          margin: 40px auto 0;
     }



}



.toasted-msg {
     font-family: $font-Poppins !important;
     max-width: 500px !important;

     .go3958317564 {
          font-size: 14px !important;
     }
}



// https://stackblitz.com/enterprise
// https://chriskalafatis.com/


.__top {
     padding-top: 100px;
     height: 100vh;
     width: 100%;
     width: 100%;
     margin: auto;

     Canvas {
          height: 100vh !important;
     }

     .grid-container.animate-grid {
          animation: scale-up 0.3s linear, fade-in 0.3s linear;
     }

     @keyframes scale-up {
          0% {
               transform: scale(0.8);

          }

          100% {
               transform: scale(1);
          }
     }

     @keyframes fade-in {
          0% {
               opacity: 0;
          }

          100% {
               opacity: 1;
          }
     }
}

.scene-container {
     position: relative;
     z-index: 1;
     display: flex;
     align-items: flex-start;
     justify-content: space-between;
     width: 100%;

     @media ($breakpoint_SM){
          flex-direction: column-reverse;
          align-items: flex-start;
     }

     .controls {
          padding: 20px;

          @media ($breakpoint_SM){
               width: 100%;
          }
     }




     .__game-outer {
          position: relative;
          z-index: 1;

          @media ($breakpoint_SM){
               width: 100%;
          }


          .scene-wrapper {
               background-color: saddlebrown;
               width: 100%;
               height: 100vh;

               @media ($breakpoint_SM){
                    height: 40vh;
               }

               canvas {
                    width: 100% !important;
                    height: 100% !important;
               }
          }
     }
     
}


// https://tympanus.net/Tutorials/codrops-kinetic-typo/
// https://tympanus.net/codrops/demos/
// https://freefrontend.com/three-js-games/
// https://wwwtyro.github.io/Astray/

// aviator -- 

// https://codepen.io/chentian/pen/yKEZjG
.__codes-app {
     background-color: #1e1e1e;
     height: 100vh;

     .__code-header {
          width: 100%;
          background-color: #191717;
          padding: 10px 16px;
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 9999;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          color: var(--txt-white);
          font-size: 14px;

          a {
               display: inline-block;


               img {
                    width: 50px;
               }
          }

          .__txt{
               max-width: 80%;
               width: 100%;
               overflow: hidden;

               p{
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 8px;
               }

               h6{
                    font-size: 12px;
                    font-weight: normal;
                    opacity: 0.5;
                    width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-bottom: 0;
               }
          }

          .back-btn{
               background-color: #373535;
               border: 1px solid transparent;
               color: #fff;
               width: 40px;
               height: 40px;
               border-radius: 50%;
               margin-right: 10px;
               display: flex;
               align-items: center;
               justify-content: center;
               transition: all 0.3s linear;

               svg{
                    font-size: 18px;
               }

               &:hover{
                    background-color: transparent;
                    border-color: var(--primary-color);
                    color: var(--primary-color);
               }
          }
     }

     .code-sec {
          height: 90%;
          padding: 1rem;
     }



     .row {
          height: 100%;
     }

     .column-sec {
          height: 100%;
     }
}
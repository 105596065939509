.__active-tool{

    .header{
        transform: translateY(0) !important;

        .__container{
            max-width: 100% !important;
        }
    }
}
.__out{
    height: 100vh;
    width: 100%;
    padding: 90px 17px 0;
    font-family: "Poppins", sans-serif;

    .row{
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;

        & > *{
            padding: 0 !important;
        }
    }

    .__settings{
        height: 100%;
        border-right: 1px solid var(--theme-dark-border);

        .title{
            margin: 10px 0;
            font-size: 14px;
        }
    }

    .__svg-code{
        border-bottom: 1px solid var(--theme-dark-border);
    }

    .jk-code{
        height: 100%;

        .__generate-code{
            overflow: scroll;
            position: relative;
            z-index: 1;

            .copy-btn{
                position: absolute;
                bottom: 40px;
                right: 40px;
                background-color: var(--txt-black);
                border-radius: 30px;
                z-index: 24;
                padding: 15px 16px;

                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }



    }


}
.__license {
     padding-top: 150px;


     .license-container {
          max-width: 600px;
          width: 100%;
          margin: auto;
     }

     .license-title {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1rem;
     }

     .license-summary {
          font-size: 1rem;
          text-align: center;
          margin-bottom: 2rem;
     }


     .license-link {
          text-decoration: none;
          display: inline-block;
          margin: 0 2px;
          color: #007bff;
     }

     .license-section {
          margin-bottom: 2.5rem;

          .section-title {
               font-size: 1.5rem;
               font-weight: bold;
               margin-bottom: 1rem;
          }

          .license-list {
               list-style-type: square;

               li {
                    margin-bottom: 0.5rem;
                    font-size: 14px;
               }
          }
     }

     .license-note {
          font-size: 1rem;
          margin-bottom: 1rem;
          padding-right: 60px;
     }

     .license-date {
          text-align: center;
          font-size: 0.875rem;
          color: #555;
     }
}
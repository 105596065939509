.__gradient-qw1 {
     padding-top: 180px;
     padding-bottom: 250px;
     font-family: $font-Poppins;

     @media ($breakpoint_MD){
          padding: 150px 10px 250px;
     }


     .qw-gradinet-heading {
          text-align: center;
          position: relative;

          .__producthunt-in{
               position: absolute;
               top: 50%;
               right: 0%;

               @media ($breakpoint_SM){
                    position: static;
                    margin-top: 20px;
                    display: inline-block;
               }
          }


          h2 {
               background: linear-gradient(to right, var(--primary-color) 20%, #fff 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               font: {}
          }

          ._headingkj{
               font-size: 35px;

               @media ($breakpoint_SM){
                    font-size: 22px;
               }
          }

          p {
               margin-bottom: 0;
               font-size: 14px;


               @media ($breakpoint_SM){
                    font-size: 13px;
               }

               span {
                    background-color: transparent;
                    padding: 0px 5px;
                    font-size: 15px;
                    letter-spacing: 3px;
                    border-radius: 4px;
                    border: 1px solid #30e87a7a;

               }

               a {
                    color: var(--primary-color);
                    text-decoration: none;
               }
          }

     }

     .___selector{
          margin: 20px auto 0;
          text-align: center;
          width: 100%;
          max-width: 400px;
          font-family: $font-Poppins;

          label{
               font-size: 14px;
               opacity: 0.7;
               margin-bottom: 10px;
          }

          select{
               display: block;
               font-size: 14px;
               padding: 10px 10px;
               font-family: $font-Poppins;
               box-shadow: unset;
               text-transform: capitalize;
               border-radius: 10px !important;

               option{
                    text-transform: capitalize;
               }
          }
     }

     .__qw-section {
          margin-top: 100px;

          .row {
               row-gap: 50px;

               @media ($breakpoint_SM){
                    row-gap: 20px;
               }
          }


          .__qw-card-gradient {
               --card_902s: #f2f2f2;
               padding: 20px;
               border-radius: 20px;
               background: #f2f2f2;
               position: relative;
               border-radius: 40px;

               @media ($breakpoint_MD){
                    padding: 15px;
                    border-radius: 14px;
               }

               &::after {
                    position: absolute;
                    top: -1px;
                    bottom: -1px;
                    left: -1px;
                    right: -1px;
                    content: "";
                    z-index: -1;
                    border-radius: 40px;
                    background: linear-gradient(71deg, #121013, #b0afb0, #121013);
                    display: none;
               }

               .__gredient-bg {
                    border-radius: 20px;
                    height: 130px;
                    width: 100%;

                    @media ($breakpoint_MD){
                         height: 100px;
                    }
               }

               .__more-sec {
                    position: relative;
                    z-index: 2;
                    top: -45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: -30px;
                    padding: 0 5px;

                    p {
                         width: 100%;
                         font-size: 13px;
                         text-align: center;
                         margin-bottom: 0;
                         margin-top: 20px;
                         display: block;
                         background-color: var(--card_902s);
                         border-radius: 40px;
                         padding: 10px 20px;

                         @media ($breakpoint_SM){
                              font-size: 12px;
                         }


                    }

               }

               .typs-gradient {
                    width: 100%;
                    padding: 0 10px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;


                    label {
                         font-size: 13px;
                         cursor: pointer;

                         @media ($breakpoint_SM){
                              font-size: 12px;
                         }

                         input {
                              -webkit-appearance: none !important;
                              appearance: none !important;
                              background-color: hsl(0deg 0% 82.3%);
                              block-size: 10px;
                              inline-size: 10px;
                              border-radius: 10px !important;
                              padding: 7px 10px;
                              transition: all .3s linear;
                              display: block;
                              margin: 0 auto 5px;
                              cursor: pointer;

                              @media ($breakpoint_SM){
                                   block-size: 10px;
                                   inline-size: 10px;
                              }
                         }

                         input[type=radio]:checked {
                              background-color: var(--primary-color) !important;
                         }

                    }
               }

               .__gradient-tools {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;
                    border: 1px solid rgb(176 175 175 / 51%);
                    border-radius: 10px;


                    .form-select {
                         background-color: transparent;
                         border: 0;
                         color: var(--txt-black);
                         font-size: 12px;
                         box-shadow: unset;
                         outline: 0;

                         @media ($breakpoint_SM){
                              font-size: 11px;

                         }

                         option {
                              font-size: 14px;
                         }
                    }

                    .btn {
                         padding: 5px 10px;
                         color: var(--txt-black);
                         outline: 0;
                         box-shadow: unset;
                         border: 0 !important;

                         svg{
                              font-size: 18px;
                         }


                         @media ($breakpoint_SM){

                              svg{
                                   font-size: 16px;
                              }

                         }
                    }


               }



          }

          .SeenBtn{
               background-color: var(--primary-color);
               padding: 10px 20px;
               border-radius: 40px;
               display: block;
               margin: 150px auto 0;
               width: 80%;
          }

          .__card_9iks{
               padding: 10px;
               border-radius: 4px;
               transition: transform 0.3s ease-in-out;
               cursor: pointer;
               z-index: 1;

             

               &:hover{
                    transform: scale(1.4);
                    z-index: 3;

                    p{
                         opacity: 1;
                    }
               }

               .id_9iks{
                    font-size: 11px;
                    opacity: 0.7;
               }

               .color_9iks{
                    margin-bottom: 0;
               }

               p{
                    opacity: 0;
                    transition: all 0.4s linear;
               }


          }
     }

    
}

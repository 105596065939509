.__wapper {
     max-width: 900px;
     width: 100%;
     margin: auto;

     @media ($breakpoint_LG) {
          padding: 0 15px;
     }
}

.banner {
     height: 90vh;
     display: flex;
     align-items: center;
     justify-content: center;
     font-family: $font-Poppins;
     padding-top: 100px;
     position: relative;
     z-index: 1;

     @media ($breakpoint_MD) {
          padding-top: 70px;
          height: auto;
     }

     .row {
          align-items: center;

          @media ($breakpoint_MD) {
               flex-direction: column-reverse;
          }
     }

     .__txt {

          h1 {
               font-size: 60px;
               font-weight: 500;
               letter-spacing: -1px;

               @media ($breakpoint_MD) {
                    font-size: 40px;
               }

               span {
                    font-weight: 300;
               }
          }

          p {
               font-size: 20px;
               margin-top: 20px;
               margin-bottom: 0;
               font-weight: 300;

               @media ($breakpoint_MD) {
                    font-size: 15px;
               }
          }

          .__links {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               margin-top: 20px;
               gap: 5px;


               .btn,
               a {
                    background-color: var(--txt-black);
                    border: 1px solid transparent;
                    border-radius: 30px;
                    font-size: 13px;
                    color: var(--txt-white);
                    text-decoration: none;
                    padding: 12px 20px;
                    transition: all 0.3s linear;


                    &:hover {
                         background-color: transparent;
                         color: var(--txt-black);
                         border-color: var(--txt-black);
                    }


                    &.--insta {
                         background-color: transparent;
                         color: var(--txt-black);
                         border: 0;
                         padding: 10px;

                         svg {
                              font-size: 22px;
                         }
                    }
               }
          }
     }



     .__canva {
          height: 500px;
          cursor: pointer;

          @media ($breakpoint_MD) {
               height: 300px;
          }

          .__homeCanvas {
               width: 100%;
               height: 100%;
          }
     }
}


.__favourite_web {
     padding: 20px 0;

     @media ($breakpoint_MD) {
          margin-top: 30px;
     }

     .section-heading {
          text-align: center;
          margin-bottom: 40px;
          font-weight: 300;
          // font-style: italic;
          font-size: 17px;
          font-family: $font-Poppins;

          @media ($breakpoint_MD) {
               font-size: 15px;
               padding: 0 20px;
          }
     }


     .__list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;

          @media ($breakpoint_LG) {
               justify-content: center;
               flex-wrap: wrap;
          }

          @media ($breakpoint_SM) {
               gap: 20px;

          }

          .company-item {
               display: inline-block;


               img {
                    height: 75px;

                    @media ($breakpoint_MD) {
                         height: 50px;
                    }
               }
          }

     }





}

.__abouts {
     margin-top: 50px;
     padding: 100px 0px 100px;

     @media ($breakpoint_MD) {
          padding: 0 0 30px 0;
     }

     .__about-heading {
          font-family: $font-Poppins;
          font-weight: 300;
          word-spacing: -.04em;
          line-height: 1.5;
          font-size: 33px;
          text-align: center;
          width: 80%;
          margin: auto;
          display: block;

          @media ($breakpoint_MD) {
               width: 100%;
               font-size: 25px;
               line-height: 1.9;

          }

          img {
               width: 70px;
               height: 60px;
               object-fit: contain;
               border-radius: 10px;

               // animation css
               transition: transform 0.5s linear, filter 0.5s linear;
               transform-style: preserve-3d;
               will-change: transform, filter;
               position: relative;
               transition-duration: 200ms;
               transition-property: transform, box-shadow;
               transition-timing-function: linear;
               transform: rotate3d(0);

               @media ($breakpoint_MD) {
                    width: 50px;
                    height: 40px;
               }

               &:hover {
                    z-index: 2;
                    border-radius: 0;
               }
          }

          a {
               text-decoration: none;
               color: var(--txt-black);
               transition: all 0.3s linear;


               &:hover {
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(90deg, #2e1959 0%, rgba(196, 110, 211, 0.5098039216) 68.2882%, #e3bc57 100%);
               }
          }
     }

     .__skill {
          margin-top: 100px;

          @media ($breakpoint_SM) {
               margin-top: 50px;
          }


          .row {
               row-gap: 20px;
               justify-content: center;
          }


          .__item {
               position: relative;
               z-index: 1;
               height: 70px;
               border-radius: 10px;
               overflow: hidden;
               border: 1px solid #000;
               display: flex;
               align-items: center;
               justify-content: center;
               transition: color 0.4s linear;
               transition-delay: 0.4s;

               @media ($breakpoint_SM) {
                    height: 50px;
               }

               &:hover {
                    color: var(--txt-white);

                    &::after {
                         opacity: 1;
                         transform: translate(-50%, -50%) scale(1);
                         border-radius: 10px;
                    }
               }

               &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 80%;
                    height: 85%;
                    z-index: -1;
                    opacity: 0;
                    transform: translate(-50%, -50%) scale(0.1);
                    border-radius: 10px;
                    background: #7e48b745;
                    filter: blur(4px);
                    border-radius: 40px;
                    transform-origin: 50% 50% 0px;
                    transition: all 0.4s cubic-bezier(0.55, 0.06, 0.68, 0.19);

               }

               .__icon {
                    img {
                         width: 40px;
                         height: 40px;


                         @media ($breakpoint_SM) {
                              width: 25px;
                              height: 25px;
                         }
                    }
               }
          }
     }

     .__Lists {
          gap: 10px;
          margin-top: 140px;

          .row {
               row-gap: 20px;
          }

          .__item {
               background-color: #f7f7f8;
               text-align: center;
               position: relative;
               z-index: 1;
               overflow: hidden;
               padding: 30px 20px 15px;
               height: 100%;
               border-radius: 7px;
               transition: all 0.2s ease-in-out;


               &:hover {
                    .__hoverItem {
                         top: 0;

                         .__hovertxt {
                              opacity: 1;
                         }
                    }
               }

               a {
                    text-decoration: none;
                    color: var(--txt-black);
               }


               .__icon {
                    margin-bottom: 20px;

                    svg {
                         font-size: 44px;
                         color: var(--txt-black);
                    }

                    img {
                         width: 40px;
                         height: 40px;
                    }
               }

               .__txt {

                    h5 {
                         font-weight: 500;
                         margin-bottom: 14px;
                    }

                    p {
                         font-size: 14px;
                    }
               }

               .__hoverItem {
                    position: absolute;
                    background-color: #000;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: all 0.2s ease-in;

                    .__hovertxt {
                         opacity: 0;
                         transition: all 0.2s ease-in-out;
                         transition-delay: 0.3s;
                         padding: 0 20px;
                         color: var(--txt-white);

                         svg {
                              font-size: 44px;
                              color: var(--txt-white);
                         }

                         img {
                              width: 40px;
                              height: 40px;
                         }

                         p {
                              margin-bottom: 0;
                              font-size: 18px;
                              margin-top: 20px;
                         }
                    }
               }

               .__viewApp {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
                    font-size: 14px;
                    margin-top: 30px;
                    opacity: 0;
                    visibility: hidden;


                    svg {
                         font-size: 15px;
                    }


                    @media ($breakpoint_MD) {
                         opacity: 1;
                         visibility: visible;
                    }
               }
          }
     }
}


.__divider {

     .scroll-container {
          width: 100%;

          .scroll-section {
               width: 100%;
               position: relative;
               transition: all 0.2s linear;

               &.--black {
                    // background-color: black;
                    background-color: var(--theme-light-section);
               }

               &.--white {
                    // background-color: var(--theme-light-section);
                    background-color: var(--theme-dark-section);
               }
          }
     }
}

.__services {
     font-family: $font-Poppins;
     background-color: var(--theme-dark-section);
     padding: 100px 0 50px;
     z-index: 1;
     position: relative;
     color: var(--txt-white);

     .__heading-sec {
          text-align: center;
          padding: 50px 0 70px;

          h1 {
               font-size: 30px;
               font-weight: 500;
               text-transform: capitalize;
               margin-bottom: 20px;
          }

          p {
               font-size: 14px;
               margin-bottom: 0;
               font-weight: 300;
               opacity: 0.6;
          }
     }

     .row {
          row-gap: 20px;
     }

     .__card {
          background: var(--theme-dark-card);
          padding: 30px 20px;
          border-radius: 20px;
          border: 1px solid var(--theme-dark-border);
          color: var(--txt-white);
          height: 100%;

          h4 {
               font-size: 22px;
               font-weight: 500;
               letter-spacing: -1px;
               text-transform: capitalize;
          }

          p {
               font-size: 14px;
               margin-top: 20px;
               margin-bottom: 0;
               font-weight: 300;
               opacity: 0.6;
          }

          .__img {
               width: 100%;
               height: 245px;

               img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
               }
          }


          &.--list-card {

               .__list {
                    height: 250px;

                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid var(--theme-dark-border);

                    ul {
                         list-style-type: square;

                         li {
                              margin-bottom: 5px;
                              font-size: 17px;
                              font-weight: 300;
                         }
                    }
               }
          }

          .__in-btn {
               display: block;
               width: 100%;

               .btn {
                    width: 100%;
                    padding: 14px 20px;
                    text-decoration: none;
                    text-align: center;
                    background-color: var(--txt-black);
                    border: 1px solid var(--theme-dark-border);
                    color: var(--txt-white);
                    border-radius: 30px;
                    font-family: $font-Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: -0.5px;
                    transition: all 0.3s linear;

                    &:hover {
                         background-color: transparent;
                         color: var(--txt-white);
                    }
               }
          }
     }
}


// faq section

.__faq {
     padding: 50px 0;
     background-color: var(--theme-dark-section);
     color: var(--txt-white);

     .__faq-header {
          text-align: center;
          padding: 50px 0 70px;

          h4 {
               font-size: 30px;
               font-weight: 500;
               text-transform: capitalize;
               margin-bottom: 20px;
          }

          p {
               font-size: 14px;
               margin-bottom: 0;
               font-weight: 300;
               opacity: 0.6;
          }
     }

     .__faq-center {
          .accordion {
               background-color: transparent;


               .accordion-item {
                    background-color: transparent;
                    color: var(--txt-white);
                    border: 1px solid var(--theme-dark-border);
                    overflow: hidden;
                    margin-bottom: 20px;
                    border-radius: 8px !important;

                    .accordion-header {
                         background: var(--theme-dark-card);

                         .accordion-button {
                              background-color: transparent;
                              box-shadow: unset;
                              border-radius: 10px;
                              color: var(--txt-white) !important;
                              display: flex;
                              align-items: center;
                              justify-content: space-between;

                              h5{
                                   margin-bottom: 0;
                                   font-size: 14px;
                                   font-weight: 300;
                                   opacity: 0.8;
                              }



                              &::after{
                                   display: none;
                              }

                              &[aria-expanded="true"]{
                                   svg{
                                        transform: rotate(180deg);
                                   }
                              }
                         }
                    }

                    .accordion-body {
                         padding: 30px 20px;


                         p {
                              font-size: 13px;
                              opacity: 0.7;
                              font-weight: 400;
                         }


                    }
               }
          }
     }
}
.ai {
     font-family: $font-Poppins;

     .__headings {
          padding: 140px 0 40px;
          background:linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(198, 147, 234, 0.2) 100%);


          .__left{
               width: 100%;

               .copt-btn{
                    background-color: transparent;
                    color: var(--txt-black);
                    border: 0;
                    padding: 10px 0px 0;
                    margin-bottom: 10px;
                    cursor: auto !important;

                    svg{
                         font-size: 40px;
                    }
               }
               
               h2 {
                    margin-bottom: 13px;
                    font-size: 27px;
               }
               
               h4{
                    font-size: 15px;
                    font-weight: 300;
               }
          }


          .__select {
               width: 60%;
               display: block;
               margin-left: auto;

               input {
                    width: 100%;
                    display: block;
                    background-color: transparent;
                    padding: 14px 20px;
                    border-radius: 30px;
                    border: 1px solid #ddd;
                    box-shadow: unset;
                    outline: 0;
                    font-family: $font-Poppins;
                    color: var(--txt-black);

                    &::placeholder{
                         color: var(--txt-black);
                    }

                    &:focus,
                    &:hover {
                         border-color: var(--primary-color);

                    }
               }
          }
     }

     .__sec {
          padding-bottom: 100px;

          .__cards {
               padding: 20px 0;
               margin-top: 20px;


               .row {
                    row-gap: 50px;
                    justify-content: center;
               }

               .hne__ahn{
                    height: 100%;
               }


               .card-qw {
                    font-family: $font-Poppins;
                    position: relative;
                    border-radius: 40px;
                    text-align: center;
                    padding: 20px;
                    background: linear-gradient(44deg, rgba(242, 237, 248, 0.3) 0%, rgba(142, 84, 184, 0.3) 56%, rgba(198, 147, 234, 0.3) 100%);
                    transition: all 0.3s linear;
                    height: 100%;
                    color: var(--txt-black);


                    .card-header {
                         height: 200px;
                         width: 100%;
                         padding: 20px;


                         h6{
                              font-size: 14px;
                              font-weight: 300;
                              margin-bottom: 0;
                              margin-top: 20px;
                         }

                         img {
                              object-fit: contain;
                              width: 70%;
                              height: 70%;
                              filter: drop-shadow(1px 1px 0px #fff);
                         }
                    }

                    .card-body {
                        
                        
                         h5 {
                              margin-bottom: 10px;
                              font-size: 18px;
                              font-size: 400;
                         }

                         p {
                              margin-bottom: 0;
                              text-align: center;
                              display: -webkit-box;
                              -webkit-line-clamp: 2;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              opacity: 0.7;
                              font-weight: 300;
                              font-size: 14px;

                         }
                    }

               }

               .qw-1::after{
                    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
               }

               .qw-2::after{
                    background: linear-gradient(71deg, #121013, #b0afb0, #121013);

               }
               .qw-3::after{
                    background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
               }

               /* CSS for react-transition-group fade-in/out effect */
               .fade-enter {
                    opacity: 0;
                    transform: translateY(25px);
               }

               .fade-enter-active {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 500ms, transform 500ms;
               }

               .fade-exit {
                    opacity: 1;
                    transform: translateY(0);
               }

               .fade-exit-active {
                    opacity: 0;
                    transform: translateY(-25px) scale(0.6);
                    transition: opacity 500ms, transform 500ms;
               }

               .__section-height {
                    height: 240px;
               }
          }
     }
}
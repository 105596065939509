.__colors-556 {
    font-family: $font-Sofia;
    margin-bottom: 100px;

    // https://colorhunt.co/palettes/popular
    // refff https://www.w3schools.com/colors/colors_picker.asp
    // https://www.w3schools.com/colors/colors_picker.asp
    &.---0-data {
        height: 100vh;
    }



    .__color-sidebar {
        background-color: saddlebrown;
        position: sticky;
        top: 100px;
    }


    .__head {
        background-color: #f4eafb;
        padding: 40px 0;
        margin-bottom: 70px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-top: 2px solid var(--txt-white);

        @media ($breakpoint_SM) {
            margin-bottom: 40px;
        }

        p {
            width: 90%;
            font-size: 14px;
            opacity: 0.8;

            @media ($breakpoint_SM) {
                width: 90%;
                font-size: 12px;
            }
        }

        .__svg {
            position: absolute;
            bottom: -40px;
            left: 0%;
            height: 100%;
            opacity: 0.05;
            display: flex;
            justify-content: center;
            gap: 20px;
            width: 100%;
            z-index: -1;

            @media ($breakpoint_SM) {
                left: 0;
                height: 110px;
                bottom: -20px;
            }

            svg {
                width: 250px;
                height: 250px;

                @media ($breakpoint_SM) {
                    width: 100%;
                    height: 100%;
                }
            }
        }


    }

    .__colors {

        .__color-card-group {
            font-size: 15px;
            transition: all 0.3s linear;
            border-radius: 20px;
            overflow: hidden;

            &:hover {

                .__color-hex {
                    opacity: 1;
                }
            }

            .__color-card {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                cursor: pointer;

                &:hover {

                    .__color-hex {
                        opacity: 1;
                    }
                }

                .__color-hex {
                    color: var(--txt-black);
                    background-color: #f4eafb40;
                    backdrop-filter: blur(10px);
                    padding: 3px 5px;
                    opacity: 0;
                }


                &:nth-child(1) {
                    height: 100px;
                }

                &:nth-child(2) {
                    height: 80px;
                }

                &:nth-child(3) {
                    height: 60px;
                }

                &:nth-child(4) {
                    height: 45px;
                }
            }

        }

    }

    .swiper-button-next, .swiper-button-prev{
        display: none !important;
    }

}



.__shade {
    margin-top: 100px;


    .__head-shade {
        margin-bottom: 40px;
    }

    .__body-shade {


        .shade-section {
            margin-bottom: 60px;
            text-align: center;

            .shade-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .custom-nav {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    .nav-btn {
                        box-shadow: unset;
                        border: 1px solid #807f7f69;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2px 4px;
                        border-radius: 1px;
                        font-size: 14px;
                    }
                }


            }

            .__shade-color-item {
                height: 150px;
                cursor: pointer;

                @media ($breakpoint_MD) {
                    height: 80px;
                }
            }

            .__shade-settigns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #f4eafb;
                padding: 10px;
                margin-top: 5px;

                @media ($breakpoint_MD) {
                    flex-direction: column;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 0;

                    @media ($breakpoint_SM) {
                        display: none;
                    }
                }

                input {
                    inline-size: 40px;
                    block-size: 17px;
                }

            }


        }


    }




}


.__rendom-color {

    .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        margin-bottom: 40px;

        @media ($breakpoint_SM) {
            gap: 0;
        }
    }

    .item-color {
        text-align: center;
        width: 100%;
    }

    .__item {
        height: 150px;
        cursor: pointer;

        @media ($breakpoint_MD) {
            height: 80px;
        }
    }

    .__item-settigns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f4eafb;
        padding: 10px;
        margin-top: 5px;

        @media ($breakpoint_MD) {
            flex-direction: column;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;

            @media ($breakpoint_SM) {
                display: none;
            }
        }

        input {
            inline-size: 40px;
            block-size: 17px;
        }

    }

    .btn-tsf {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        background-color: var(--txt-black);
        border: 1px solid transparent;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        color: var(--txt-white);
        font-size: 14px;
        font-weight: 600;

        &:hover {
            background-color: transparent;
            border-color: var(--txt-black);
            color: var(--txt-black);
        }
    }

    .refresh-icon {
        transition: transform 0.6s ease-in-out;
    }

    .refresh-icon.rotate {
        transform: rotate(360deg);
    }
}


.__loading {
    text-align: center;
}
.__aboutpage{

    .second-sec{
        margin-top: 20px;
        padding-bottom: 100px !important;
    }
}


.__svg-sec{
    padding-top: 200px;
    overflow: hidden;
    position: relative;
    z-index: 1;


    .__top-sec{
        height: 600px;
        background-color: silver;
    }

    .__animation-sec{
        padding: 200px 0;
        position: relative;
        background-color: rgba(135, 207, 235, 0.343);

        .__svg-outer{
            position: absolute;
            top: 0%;
            left: 0;
            width: 100%;
    
        }
    }

   
}


// task page scss

.task{
    padding-top: 200px;
    font-family: $font-Poppins;
    padding-bottom: 250px;

    h1{
        text-align: center;
        margin-bottom: 20px;
    }

    .card-task{
        background-color: #f2f2f21a;
        padding: 20px;
        width: 100%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h2{
            margin-bottom: 5px;
        }

        p{
            margin-bottom: 0;
            opacity: 0.7;
            width: 70%;

        }

        a{
            text-decoration: none;
            color: var(--primary-color);
        }

    }
}
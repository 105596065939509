.blogPage {
    font-family: "Arial", sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;

    .header {
        text-align: center;
        margin-bottom: 40px;

        .title {
            font-size: 2.5rem;
            font-weight: bold;
            color: #2f4653;
        }

        .subtitle {
            font-size: 1.2rem;
            color: #555;
        }
    }

    .blogList {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }

    .blogCard {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        .blogTitle {
            font-size: 1.5rem;
            margin-bottom: 10px;
            color: #2f4653;
        }

        .blogDate {
            font-size: 0.9rem;
            color: #777;
            margin-bottom: 10px;
        }

        .blogExcerpt {
            font-size: 1rem;
            color: #555;
            margin-bottom: 15px;
        }

        .readMore {
            display: inline-block;
            font-size: 0.9rem;
            color: #007bff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}